import Debug from 'debug';
import User from './User.js';
const debug = Debug('cbio.users');
export default (api => ({
  get: async (id, query) => {
    debug('get()', id, query);
    return new User(api, await api.fetch(User.url({
      id
    }), query));
  }
}));
export { User };