<s1-input-label class="bootstrap-theme" [label]="label" [required]="required" [grouped]="labelGrouped">
  <ng-select class="form-control s1"
    virtualScroll="true"
    [placeholder]="placeholder"
    [formControl]="control"
    [items]="itemsList"
    [readonly]="readonly"
    [ngClass]="{ 'is-invalid' : !checkIsValid() }"
    [bindValue]="bindAllObject ? null : 'code'"
    [clearable]="clearable"
    (clear)="cleared()"
    (change)="changed($event)">
    <ng-template ng-label-tmp ng-option-tmp let-item="item">
      <span *ngIf="item.deprecated">[<b class="option-info">{{ 'base.deprecated' | translate }}</b>]</span>{{ item.label }}
    </ng-template>
  </ng-select>
</s1-input-label>
