<s1-modal #modal [title]="operativeSystemMode ? 'assets.asset.title_new_os' : ('assets.asset.title' | translate: { asset: asset?.name || ''})">
  <s1-card>
    <form [formGroup]="assetForm">
      <div class="row text-left">
        <mdm-input-asset-type-select [ngClass]="operativeSystemMode ? 'd-none' : ''" [includeOs]="includeOs || operativeSystemMode" class="col mb-0" [control]="assetForm.controls.assetType" [bindAllObject]="false" (onSelectClear)="typeChange($event)" (onSelectChange)="typeChange($event)"></mdm-input-asset-type-select>
        <s1-input-switch *ngIf="isType('Tool')" class="col-auto my-2" [control]="assetForm.controls.flgEsecViaStringa" [label]="'assets.asset.fields.flgEsecViaStringa'"></s1-input-switch>

        <s1-input-text *ngIf="!isType('Tool') || !(isType('Tool') && assetForm.controls.flgEsecViaStringa.value)" class="col-12 mb-2" [control]="assetForm.controls.name" [label]="'assets.asset.fields.name'" [readonly]="assetForm.controls.localFile.value" [labelGrouped]="false"></s1-input-text>
        <s1-input-text class="col-12 my-2" [control]="assetForm.controls.description" [label]="'assets.asset.fields.description'" [labelGrouped]="false"></s1-input-text>
        <s1-input-textarea *ngIf="(isType('Tool') && assetForm.controls.flgEsecViaStringa.value)" class="col-12 mb-4" [control]="assetForm.controls.stringaEsec" [label]="'assets.asset.fields.stringaEsec'"></s1-input-textarea>
        <s1-input-text *ngIf="!isType('Tool') || !(isType('Tool') && assetForm.controls.flgEsecViaStringa.value)" class="col-9 my-2" [control]="assetForm.controls.source" [label]="'assets.asset.fields.source'" [readonly]="assetForm.controls.localFile.value" [labelGrouped]="false" (onChange)="changeInput($event)"></s1-input-text>

        <s1-input-switch *ngIf="!isType('Tool') || !(isType('Tool') && assetForm.controls.flgEsecViaStringa.value)" class="col-auto my-2" [control]="assetForm.controls.localFile" [label]="'base.upload_file'"></s1-input-switch>
        <s1-input-switch [style.display]="assetForm.controls.flgExtract.value != null ? 'block' : 'none' "  class="col-auto my-2" [control]="assetForm.controls.flgExtract" [label]="'base.extract_file'"></s1-input-switch>

        <ng-container *ngIf="!(isType('Tool') && assetForm.controls.flgEsecViaStringa.value) && assetForm.controls.localFile.value">
          <div class="col-9 col-lg-auto mt-0 mb-2 text-center">
            <label for="upload" class="file-upload">
              <s1-button [type]="s1ButtonType.Browse"></s1-button>
              <input id="upload" type="file" ng2FileSelect [uploader]="uploader" (onFileSelected)="fileSelected()" (change)="fileChanged($event)"/>
              <label class="mx-2 mt-2 mt-lg-0" *ngIf="isType('Operating System')">(max size {{ maxSizeOS/1024/1024 }} MB)</label>
              <label class="mx-2 mt-2 mt-lg-0" *ngIf="!isType('Operating System')">(max size {{ maxSize/1024/1024 }} MB)</label>
              <label class="text-danger" *ngIf="exeecedSizeError" nowrap>{{ 'msg.file_size_exceeds' | translate }}</label>
            </label>
          </div>
        </ng-container>

        <div class="col-12" *ngIf="!assetForm.controls.localFile.value && placeholders?.length>0">
          <p class="small">{{ 'base.placeholders_in_use' | translate }}<span class="mx-1 font-weight-bold" *ngFor="let plc of placeholders">{{plc}}</span></p>
        </div>

        <s1-input-text [style.display]="isType('App') ? 'block' : 'none' " class="col-12 my-2" [control]="assetForm.controls.packageName" [label]="'assets.asset.fields.packageName'" [labelGrouped]="false"></s1-input-text>
        
        <s1-input-text [style.display]="isType('File') ? 'block' : 'none' " class="col-12 my-2" [control]="assetForm.controls.target" [label]="'assets.asset.fields.target'" [labelGrouped]="false"></s1-input-text>
        
        <mdm-input-executewith-type-select
          [style.display]="isType('Tool') ? 'block' : 'none' " class="col-12 my-2"
          [control]="assetForm.controls.executeWith"
          [bindAllObject]="false"
          [additionalTypes]="isType('Tool') ? ['SCAN2DEPLOY', 'KNOX'] : null"
          (onSelectClear)="executeWithChange($event)"
          (onSelectChange)="executeWithChange($event)">
        </mdm-input-executewith-type-select>

        <s1-input-switch [style.display]="(isType('File') || isType('Tool')) ? 'block' : 'none' " class="col-12 my-2" [control]="assetForm.controls.placeholder" [label]="'assets.asset.fields.placeholder'"></s1-input-switch>
        
        <div class="col-12 mt-1 text-danger">
          <ng-container *ngIf="hasError">{{'base.fields_required' | translate }}</ng-container>
        </div> 
        <div class="col-12 mb-1 text-center">
          <s1-button [type]="s1ButtonType.Save" (onClick)="save()"></s1-button>
        </div>
      </div>
    </form>
  </s1-card>
</s1-modal>