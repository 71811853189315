<s1-input-select class="col-6 mb-2 fix-margin"
  [control]="control"
  [bindAllObject]="bindAllObject"
  [itemsList]="policyList"
  [label]="'fields.input.aeProfile'"
  [readonly]="readonly"
  [bindAllObject]="bindAllObject"
  [labelGrouped]="false"
  [placeholder]="placeholder"
  (onSelectChange)="changed($event)" >
</s1-input-select>
