import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1ButtonType } from '@app/s1';
import { AeService, IApplication, IApplicationPolicy, IApplicationSearchParams } from '@app/shared/services/ae.service';

@Component({
  selector: 'profile-ae-form-configuration',
  templateUrl: './profile-ae-form-configuration.component.html',
  styleUrls: ['./profile-ae-form-configuration.component.scss']
})
export class ProfileAeFormConfigurationComponent implements OnInit {

  @Output() onSaved = new EventEmitter<any>();
  
  s1ButtonType = S1ButtonType;
  configurationForm: UntypedFormGroup;
  installTypeOptions = [];
  autoUpdateModeOptions = [];
  permissionPolicyOptions = [];
  
  application: IApplication;
  applicationPolicy: IApplicationPolicy;
  applicationPolicyIndex: number;

  packageNamesAlreadyUsedInPolicy = [];
  packageNameAlreadyUsed = false;
  packageNameNotFound = false;

  managedConfigurationsFormArray: UntypedFormArray;
  managedConfigurationsArrayOptions = [];
  
  applicationPermissionsFormArray: UntypedFormArray;
  applicationPermissionsOptions = [];

  automaticPackageValue = false;
  showManagedConfigurations = false;
  
  constructor(private formBuilder: UntypedFormBuilder, private aeService: AeService, private settings: SettingsService) {
    this.configurationForm = this.formBuilder.group({
      packageName: [null],
      installType: [null, Validators.required],
      autoUpdateMode: [null],
      minimunVersionCode: [null],
      defaultPermissionPolicy: [null],
      newPermissionKey: [null],
      newPermissionPolicy: [null],
      managedConfigurations: this.formBuilder.array([])
    });
    this.configurationForm.get('packageName').markAsTouched();
    this.configurationForm.get('installType').markAsTouched();
    this.installTypeOptions = [
      {"code": "INSTALL_TYPE_UNSPECIFIED", "label": "INSTALL_TYPE_UNSPECIFIED"},
      {"code:": "PREINSTALLED", "label": "PREINSTALLED"},
      {"code": "FORCE_INSTALLED", "label": "FORCE_INSTALLED"},
      {"code": "BLOCKED", "label": "BLOCKED"},
      {"code": "AVAILABLE", "label": "AVAILABLE"},
      {"code": "REQUIRED_FOR_SETUP", "label": "REQUIRED_FOR_SETUP"},
      {"code": "KIOSK", "label": "KIOSK"}
    ];
    this.autoUpdateModeOptions = [
      {"code": "AUTO_UPDATE_MODE_UNSPECIFIED", "label": "AUTO_UPDATE_MODE_UNSPECIFIED"},
      {"code": "AUTO_UPDATE_DEFAULT", "label": "AUTO_UPDATE_DEFAULT"},
      {"code": "AUTO_UPDATE_POSTPONED", "label": "AUTO_UPDATE_POSTPONED"},
      {"code": "AUTO_UPDATE_HIGH_PRIORITY", "label": "AUTO_UPDATE_HIGH_PRIORITY"}
    ];
    this.permissionPolicyOptions = [
      {"code": "PERMISSION_POLICY_UNSPECIFIED", "label": "PERMISSION_POLICY_UNSPECIFIED"},
      {"code": "PROMPT", "label": "PROMPT"},
      {"code": "GRANT", "label": "GRANT"},
      {"code": "DENY", "label": "DENY"}
    ];
  }

  ngOnInit(): void {
    this.configurationForm.controls.packageName.valueChanges.subscribe( value => {
        if(value) {
          this.packageNameAlreadyUsed = false;
          this.packageNameNotFound = false;
        }
      }
    );
  }

  setControlError(control, error = null) {
    if(error) {
      control.markAsTouched();
      control.setErrors({'incorrect': true});
    } else {
      control.setErrors(null);
    }
  }
  
  inizializza(packageNameAlreadyUsedInPolicy: string[], applicationPolicy: any, applicationPolicyIndex: number, packageName?: string) {
    console.log('al caricamento del form FIGLIO, mi arriva la policy dalla TABLE del padre >>>>>>>>>>>>>>>>>>>>>', applicationPolicy);

    this.packageNamesAlreadyUsedInPolicy = packageNameAlreadyUsedInPolicy;
    this.applicationPolicy = applicationPolicy;
    this.applicationPolicyIndex = applicationPolicyIndex;
    this.application = null;
    this.packageNameAlreadyUsed = false;
    this.packageNameNotFound = false;

    //reset
    this.configurationForm.patchValue({
      packageName: packageName ? packageName : null,
      installType: null,
      autoUpdateMode: null,
      minimunVersionCode: null,
      defaultPermissionPolicy: null,
      newPermissionKey: null,
      newPermissionPolicy: null
    });

    this.setApplicationPolicyForm(applicationPolicy?.packageName);
    // If packageName is available at initialization the application info must be loaded automatically
    if (packageName) {
      this.loadApplication(packageName);
      this.automaticPackageValue = true;
    }
  }

  loadApplication(packageName: any) {
    this.packageNameNotFound = false;
    if(this.packageNamesAlreadyUsedInPolicy?.includes(packageName)) {
      this.setControlError(this.configurationForm.controls.packageName, true);
      this.packageNameAlreadyUsed = true;
      this.setApplicationPolicyForm(packageName);
    } else {
      this.packageNameAlreadyUsed = false;
      this.setApplicationPolicyForm(packageName);
    }
  }

  setApplicationPolicyForm(packageName: string) {
    
    this.managedConfigurationsFormArray = this.formBuilder.array([]);
    this.managedConfigurationsArrayOptions = [];
    this.applicationPermissionsFormArray =  this.formBuilder.array([]);

    if(packageName && !this.packageNameAlreadyUsed && !this.packageNameNotFound) {
      //nuova configurazione, applicationPolicy null, filtro su packageNameAlreadyUsedInPolicy
      //modifica configurazione, packageName non modificabile
      //get struttura da packageName
      let parameters: IApplicationSearchParams = {
        packageName: packageName,
        idCompany: this.settings.getCompany().code,
      }
      this.aeService.getApplication(parameters).subscribe(application => {
        this.application = application;
        console.log('this.application ------------> ', this.application);
        console.log('this.applicationPolicy ------------> ', this.applicationPolicy);

        if(!this.application) {
          this.setControlError(this.configurationForm.controls.packageName, true);
          this.packageNameNotFound = true;
        } else {
          this.application.packageName = packageName;
          this.packageNameNotFound = false;
          
          //init form permissions: dal json mi prendo il nodo permissions e lo carico nella select.
          this.applicationPermissionsOptions = this.application.permissions.map(applicationPermission => {
            return { code: applicationPermission.permissionId, label: applicationPermission.permissionId.split('.').pop() };
          });
          //carico quelli precedentemente aggiunti nell'array
          for (let i = 0; i < this.applicationPolicy?.permissions.length; i++) {
            this.applicationPermissionsFormArray.push(this.formBuilder.control(this.applicationPolicy?.permissions[i].policy));
          }

          /**
           * init form managedConfigurations values (serve in modifica)
           */
          let managedConfigurationsStringValues = Array(this.application.managedProperties?.length).fill('');//array lungo quante sono le managedProperties con stringhe vuote: ['', '', '', ... , '', '', '', '']
          let managedConfigurationsArrayValues = Array(this.application.managedProperties?.length).fill([]);//array lungo quante sono le managedProperties con array vuoti: [Array(0), Array(0), Array(0), ... , Array(0), Array(0), Array(0), Array(0)]
          this.managedConfigurationsArrayOptions = Array(this.application.managedProperties?.length).fill([]);//array lungo quante sono le managedProperties con array vuoti: [Array(0), Array(0), Array(0), ... , Array(0), Array(0), Array(0), Array(0)]


          //imposta eventuale valore nei campi prendendoli da this.applicationPolicy (in caso di modifica) ....
          if(this.applicationPolicy?.managedConfigurations) {

            managedConfigurationsStringValues = this.application.managedProperties.map(applicationProperty => {
              if(['STRING','HIDDEN','INTEGER','BOOL','CHOICE'].includes(applicationProperty.type)) {
                let managedConfiguration = this.applicationPolicy.managedConfigurations.find(managedConfiguration => managedConfiguration.key == applicationProperty.key);
                return managedConfiguration?.value;
              } else {
                return null;
              }
            });
            managedConfigurationsArrayValues = this.application.managedProperties.map(applicationProperty => {
              if(['MULTISELECT'].includes(applicationProperty.type)) {
                let managedConfiguration = this.applicationPolicy.managedConfigurations.find(managedConfiguration => managedConfiguration.key == applicationProperty.key);
                return managedConfiguration?.value;
              } else {
                return [];
              }
            });

          }
          //setup options
          this.managedConfigurationsArrayOptions = this.application?.managedProperties?.map(applicationProperty => {
            if(['CHOICE'].includes(applicationProperty.type)) {
              return applicationProperty.entries.map(e => e.code);
            } else if(['MULTISELECT'].includes(applicationProperty.type)) {
              return applicationProperty.entries;
            } else {
              return [];
            }
          });
          /**
           * a questo punto nei due vettori managedConfigurationsStringValues e managedConfigurationsArrayValues ho i valori da settare all'apertura form
           * in this.managedConfigurationsArrayOptions
           *
           *           console.log('managedConfigurationsStringValues',managedConfigurationsStringValues);
           *           console.log('managedConfigurationsArrayValues',managedConfigurationsArrayValues);
           *           console.log('this.managedConfigurationsArrayOptions',this.managedConfigurationsArrayOptions);
           */

          //creo array this.managedConfigurationsFormArray con i campi dinamici del form!
          //this.callRecursively(this.application.managedProperties);
          this.application.managedProperties = this.callIteratively(this.application.managedProperties);


          /**
           * creo array this.managedConfigurationsFormArray con i campi dinamici del form!
           * se in edit mi precarica
           * se in insert lascia vuoti
           */

          for (let i = 0; i < this.application.managedProperties?.length; i++) {
            if(['STRING','HIDDEN','INTEGER','BOOL','CHOICE'].includes(this.application.managedProperties[i].type)) {
              this.managedConfigurationsFormArray.push(this.formBuilder.control(managedConfigurationsStringValues[i]));
              //this.managedConfigurations.push(this.formBuilder.control(managedConfigurationsStringValues[i]));
              //this.managedConfigurationsFormArray.push(this.formBuilder.control(this.application.managedProperties[i].editValue));
            } else if(['MULTISELECT'].includes(this.application.managedProperties[i].type)) {
              this.managedConfigurationsFormArray.push(this.formBuilder.control(managedConfigurationsArrayValues[i]));
              //this.managedConfigurations.push(this.formBuilder.control(managedConfigurationsStringValues[i]));
            } else {
              this.managedConfigurationsFormArray.push(this.formBuilder.control(null));
              //this.managedConfigurations.push(this.formBuilder.control(null));
            }
          }
          //console.log(this.managedConfigurationsFormArray);

          this.configurationForm.patchValue({
            packageName: this.application?.packageName,
            installType: this.applicationPolicy?.installType,
            autoUpdateMode: this.applicationPolicy?.autoUpdateMode,
            minimunVersionCode: this.applicationPolicy?.minimunVersionCode,
            defaultPermissionPolicy: this.applicationPolicy?.defaultPermissionPolicy,
            newPermissionKey: null,
            newPermissionPolicy: null,
            //managedConfigurations: this.managedConfigurationsFormArray
          });
        }
        
      });
    }
  }

  get managedConfigurations(): UntypedFormArray {
    return <UntypedFormArray>this.configurationForm.get('managedConfigurations');
  }


  cloneArrayBundle(dallaPosizione:number, allaPosizione:number){

    console.log(this.application.managedProperties[dallaPosizione].bundles);
    console.log('duplica dal posto: ', dallaPosizione, 'al posto: ', allaPosizione);


    let bunble_array = this.application.managedProperties[dallaPosizione].bundles;
    console.log('sto per allungare di questi vcalori:', bunble_array);

    //this.application.managedProperties.push(bunble_array);
    //this.application.managedProperties = [ ...this.application.managedProperties, ...bunble_array];
    //this.application.managedProperties = this.application.managedProperties.concat(bunble_array);
    for (let i = 0; i < bunble_array.length; i++) {
      this.application.managedProperties.splice(allaPosizione + i, 0, bunble_array[i]);
      this.managedConfigurationsFormArray.push(this.formBuilder.control(null));
      //if(bunble_array[i].type !== 'BUNDLE' && bunble_array[i] !== 'BUNDLE_ARRAY') this.managedConfigurations.push(bunble_array[i]);
      //if(bunble_array[i].type === 'BUNDLE' || bunble_array[i] === 'BUNDLE_ARRAY') console.log(bunble_array[i]);
    }


    //this.application.managedProperties.splice(allaPosizione, 0, this.application.managedProperties[dallaPosizione].bundles);
    //this.application.managedProperties.splice(allaPosizione, 0, this.application.managedProperties[allaPosizione].bundles);

/*
    //alert('ciao a tutti, posizione: ' + posizione);

    //dal parametro dallaPosizione, vado a ritroso fina a trovare il nodo BUNDLE_ARRAY_START dal quale prendere i campi da duplicare
    let indexArray = 0;
    //duplico gli elementi
    //let arrayElements = [];

    //mi clc index del bundle_array
    for (indexArray = dallaPosizione;  ; indexArray--) {
      if(this.application.managedProperties[indexArray].type === 'BUNDLE_ARRAY_START')
        break;
    }

    //this.managedConfigurationsFormArray.insert(posizione, this.formBuilder.control(null));
    //this.application.managedProperties.splice(posizione, 0, ramo);
    //duplico tutto il BANDLE_ARRAY
    //this.application.managedProperties.splice(allaPosizione, 0, this.application.managedProperties[dallaPosizione]);
    //duplico gli elementi del form
    let bunble_array = this.application.managedProperties[indexArray].bundles;
    //console.log(bunble_array);
    for (let i = 0; i < bunble_array.length; i++) {
      this.application.managedProperties.splice(allaPosizione + i + 1, 0, this.application.managedProperties[indexArray].bundles[i]);
    }
    console.log('duplica dal posto: ', indexArray, 'al posto: ', allaPosizione);

    /*let bunble_array = this.application.managedProperties[dallaPosizione].bundles;
    //this.application.managedProperties[dallaPosizione].bundles = [ ...this.application.managedProperties[dallaPosizione].bundles, ...bunble_array];
    this.application.managedProperties[dallaPosizione].bundles.push(...bunble_array);*/
    console.log('node [DOPO] -------------------------> ', this.application.managedProperties);
  }

  callRecursively(node: any) {
    //doSomethingWithNode;
    console.log(node.type);

    if (node.nestedProperties) {
      node.nestedProperties.forEach(childNode => {
        this.callRecursively(childNode);
      });
    }
  }

  callIteratively2() {
    //doSomethingWithNode;
    var tree = this.application.managedProperties;
    console.log('node [PRIMA]-------------------------> ', tree);
    var temp = [];

    interface ramo {
      type: string;
      description: string;
    }

    for (let i = 0; i < tree.length; i++) {
      //se ha figli
      var node: any;
      node = tree[i];

      if (node.nestedProperties) {
        //inseriscimi nodo ramo
        let ramo: ramo = {
          type: 'BUNDLE',
          description: node.description
        };
        temp.push(ramo);

        for (let j = 0; j < node.nestedProperties.length; j++) {
          var node1: any;
          node1 = node.nestedProperties[j];

          if (node1.nestedProperties) {
            //inseriscimi nodo ramo
            let ramo1: ramo = {
              type: 'BUNDLE',
              description: node1.description
            };
            temp.push(ramo1);

            for (let k = 0; k < node1.nestedProperties.length; k++) {
              var node2: any;
              node2 = node1.nestedProperties[k];

              if (node2.nestedProperties) {
                //inseriscimi nodo ramo
                let ramo2: ramo = {
                  type: 'BUNDLE',
                  description: node2.description
                };
                temp.push(ramo2);

                for (let f = 0; f < node2.nestedProperties.length; f++) {
                  var node3: any;
                  node3 = node2.nestedProperties[f];

                  if (node3.nestedProperties) {
                    let ramo3: ramo = {
                      type: 'BUNDLE',
                      description: node3.description
                    };
                    temp.push(ramo3);

                    for (let g = 0; g < node3.nestedProperties.length; g++) {
                      var node4: any;
                      node4 = node3.nestedProperties[g];

                      if (node4.nestedProperties) {
                        //inseriscimi cmq il nodo per stampare instesazione sottogruppo
                        let ramo4: ramo = {
                          type: 'BUNDLE',
                          description: node4.description
                        };
                        temp.push(ramo4);

                        for (let h = 0; h < node4.nestedProperties.length; h++) {
                          var node5: any;
                          node5 = node4.nestedProperties[h];

                          if (node5.nestedProperties) {
                            //inseriscimi cmq il nodo per stampare instesazione sottogruppo
                            let ramo5: ramo = {
                              type: 'BUNDLE',
                              description: node5.description
                            };
                            temp.push(ramo5);

                            //...

                          }else{
                            temp.push(node5);
                          }
                        }
                      }else{
                        temp.push(node4);
                      }
                    }
                  }else{
                    temp.push(node3);
                  }
                }
              }else{
                temp.push(node2);
              }
            }
          }else{
            temp.push(node1);
          }
        }
      }else{
        node.styleNode = '';
        temp.push(node);
      }
    }

    console.log('node [DOPO] -------------------------> ', temp);
    return temp;
  }

  callIteratively(tree: any) {
    //var tree = this.application.managedProperties;
    console.log('node [PRIMA]-------------------------> ', tree);
    var temp = [];
    var keyRebuild = '';

    interface ramo {
      type: string;
      description: string;
      bundles: any;
    }

    for (let i = 0; i < tree?.length; i++) {
      //se ha figli
      var node: any;
      node = tree[i];

      if (node.nestedProperties) {
        keyRebuild = node.key;
        var bundles0 = [];//qui ci metto, eventulamente il sottoarray di un bundle_array

        //inseriscimi nodo ramo
        if(node.type == 'BUNDLE'){
          let ramo: ramo = {
            type: 'BUNDLE',
            description: node.description,
            bundles: []
          };
          temp.push(ramo);
          keyRebuild = keyRebuild + '!B' + '!' + i;
        }

        //apro il blocco BUNDLE_ARRAY
        if(node.type == 'BUNDLE_ARRAY'){
          bundles0 = this.callIteratively(node.nestedProperties);
          let ramo: ramo = {
            type: 'BUNDLE_ARRAY_START',
            description: 'START',
            bundles: bundles0
          };
          temp.push(ramo);
          keyRebuild = keyRebuild + '!A' + '!' + i;
          //chiama funzione a parte per creare array piatto a metterlo su questo nodo.
          //continue;
        }


        for (let j = 0; j < node.nestedProperties.length; j++) {
          var node1: any;
          node1 = node.nestedProperties[j];

          if (node1.nestedProperties) {
            keyRebuild = keyRebuild + '|' + node1.key;
            var bundles1 = [];//qui ci metto, eventulamente il sottoarray di un bundle_array

            if(node1.type == 'BUNDLE'){
              //inseriscimi nodo ramo
              let ramo1: ramo = {
                type: 'BUNDLE',
                description: node1.description,
                bundles: []
              };
              temp.push(ramo1);
              keyRebuild = keyRebuild + '!B' + '!' + j;
            }

            //apro il blocco BUNDLE_ARRAY
            if(node1.type == 'BUNDLE_ARRAY'){
              bundles1 = this.callIteratively(node1.nestedProperties);
              let ramo: ramo = {
                type: 'BUNDLE_ARRAY_START',
                description: 'START',
                bundles: bundles1
              };
              temp.push(ramo);
              keyRebuild = keyRebuild + '!A' + '!' + j;
              //chiama funzione a parte per creare array piatto a metterlo su questo nodo.
              //continue;
            }

            for (let k = 0; k < node1.nestedProperties.length; k++) {
              var node2: any;
              node2 = node1.nestedProperties[k];

              if (node2.nestedProperties) {
                keyRebuild = keyRebuild + '|' + node2.key;
                var bundles2 = [];//qui ci metto, eventulamente il sottoarray di un bundle_array

                if(node2.type == 'BUNDLE'){
                  //inseriscimi nodo ramo
                  let ramo2: ramo = {
                    type: 'BUNDLE',
                    description: node2.description,
                    bundles: []
                  };
                  temp.push(ramo2);
                  keyRebuild = keyRebuild + '!B' + '!' + k;
                }

                //apro il blocco BUNDLE_ARRAY
                if(node2.type == 'BUNDLE_ARRAY'){
                  bundles2 = this.callIteratively(node2.nestedProperties);
                  let ramo: ramo = {
                    type: 'BUNDLE_ARRAY_START',
                    description: 'START',
                    bundles: bundles2
                  };
                  temp.push(ramo);
                  keyRebuild = keyRebuild + '!A' + '!' + k;
                  //chiama funzione a parte per creare array piatto a metterlo su questo nodo.
                  //continue;
                }

                for (let f = 0; f < node2.nestedProperties.length; f++) {
                  var node3: any;
                  node3 = node2.nestedProperties[f];

                  if (node3.nestedProperties) {
                    keyRebuild = keyRebuild + '|' + node3.key;
                    var bundles3 = [];//qui ci metto, eventulamente il sottoarray di un bundle_array

                    if(node3.type == 'BUNDLE'){
                      let ramo3: ramo = {
                        type: 'BUNDLE',
                        description: node3.description,
                        bundles: []
                      };
                      temp.push(ramo3);
                      keyRebuild = keyRebuild + '!B' + '!' + f;
                    }

                    //apro il blocco BUNDLE_ARRAY
                    if(node3.type == 'BUNDLE_ARRAY'){
                      bundles3 = this.callIteratively(node3.nestedProperties);
                      let ramo: ramo = {
                        type: 'BUNDLE_ARRAY_START',
                        description: 'START',
                        bundles: bundles3
                      };
                      temp.push(ramo);
                      keyRebuild = keyRebuild + '!A' + '!' + f;
                      //chiama funzione a parte per creare array piatto a metterlo su questo nodo.
                      //continue;
                    }

                    for (let g = 0; g < node3.nestedProperties.length; g++) {
                      var node4: any;
                      node4 = node3.nestedProperties[g];

                      if (node4.nestedProperties) {
                        keyRebuild = keyRebuild + '|' + node4.key;
                        var bundles4 = [];//qui ci metto, eventulamente il sottoarray di un bundle_array

                        //inseriscimi cmq il nodo per stampare instesazione sottogruppo
                        if(node4.type == 'BUNDLE'){
                          let ramo4: ramo = {
                            type: 'BUNDLE',
                            description: node4.description,
                            bundles: []
                          };
                          temp.push(ramo4);
                          keyRebuild = keyRebuild + '!B' + '!' + g;
                        }
                        //apro il blocco BUNDLE_ARRAY
                        if(node4.type == 'BUNDLE_ARRAY'){
                          bundles4 = this.callIteratively(node4.nestedProperties);
                          let ramo: ramo = {
                            type: 'BUNDLE_ARRAY_START',
                            description: 'START',
                            bundles: bundles4
                          };
                          temp.push(ramo);
                          keyRebuild = keyRebuild + '!A' + '!' + g;
                          //chiama funzione a parte per creare array piatto a metterlo su questo nodo.
                          //continue;
                        }

                        for (let h = 0; h < node4.nestedProperties.length; h++) {
                          var node5: any;
                          node5 = node4.nestedProperties[h];

                          if (node5.nestedProperties) {
                            keyRebuild = keyRebuild + '|' + node5.key;
                            var bundles5 = [];//qui ci metto, eventulamente il sottoarray di un bundle_array

                            //inseriscimi cmq il nodo per stampare instesazione sottogruppo
                            if(node5.type == 'BUNDLE'){
                              let ramo5: ramo = {
                                type: 'BUNDLE',
                                description: node5.description,
                                bundles: []
                              };
                              temp.push(ramo5);
                              keyRebuild = keyRebuild + '!B' + '!' + h;
                            }
                            //apro il blocco BUNDLE_ARRAY
                            if(node5.type == 'BUNDLE_ARRAY'){
                              bundles5 = this.callIteratively(node5.nestedProperties);
                              let ramo: ramo = {
                                type: 'BUNDLE_ARRAY_START',
                                description: 'START',
                                bundles: bundles5
                              };
                              temp.push(ramo);
                              keyRebuild = keyRebuild + '!A' + '!' + h;
                              //chiama funzione a parte per creare array piatto a metterlo su questo nodo.
                              //continue;
                            }

                            for (let m = 0; m < node5.nestedProperties.length; m++) {
                              var node6: any;
                              node6 = node5.nestedProperties[m];
                              var bundles6 = [];//qui ci metto, eventulamente il sottoarray di un bundle_array

                              if (node6.nestedProperties) {
                                keyRebuild = keyRebuild + '|' + node6.key;
                                var bundles6 = [];//qui ci metto, eventulamente il sottoarray di un bundle_array

                                //inseriscimi cmq il nodo per stampare instesazione sottogruppo
                                if(node6.type == 'BUNDLE'){
                                  let ramo6: ramo = {
                                    type: 'BUNDLE',
                                    description: node6.description,
                                    bundles: []
                                  };
                                  temp.push(ramo6);
                                  keyRebuild = keyRebuild + '!B' + '!' + m;
                                }
                                //apro il blocco BUNDLE_ARRAY
                                if(node6.type == 'BUNDLE_ARRAY'){
                                  bundles6 = this._buildBundlesArray(node6.nestedProperties);
                                  let ramo: ramo = {
                                    type: 'BUNDLE_ARRAY_START',
                                    description: 'START',
                                    bundles: bundles6
                                  };
                                  temp.push(ramo);
                                  keyRebuild = keyRebuild + '!A' + '!' + m;
                                  //chiama funzione a parte per creare array piatto a metterlo su questo nodo.
                                  //continue;
                                }

                                for (let n = 0; n < node6.nestedProperties.length; n++) {
                                  var node7: any;
                                  node7 = node6.nestedProperties[m];
                                  var bundles7 = [];//qui ci metto, eventulamente il sottoarray di un bundle_array

                                  if (node7.nestedProperties) {

                                    //...

                                  }else{
                                    node7.key = keyRebuild + '|' + node7.key + '!F' + '!' + n;
                                    temp.push(node7);
                                  }

                                }

                                //chiudo il blocco BUNDLE_ARRAY
                                if(node6.type == 'BUNDLE_ARRAY'){
                                  let ramo: ramo = {
                                    type: 'BUNDLE_ARRAY_STOP',
                                    description: 'STOP',
                                    bundles: bundles6
                                  };
                                  temp.push(ramo);
                                }

                              }else{
                                node6.key = keyRebuild + '|' + node6.key + '!F' + '!' + m;
                                temp.push(node6);
                              }

                            }


                            //chiudo il blocco BUNDLE_ARRAY
                            if(node5.type == 'BUNDLE_ARRAY'){
                              let ramo: ramo = {
                                type: 'BUNDLE_ARRAY_STOP',
                                description: 'STOP',
                                bundles: bundles5
                              };
                              temp.push(ramo);
                            }

                          }else{
                            node5.key = keyRebuild + '|' + node5.key + '!F' + '!' + h;
                            temp.push(node5);
                          }
                        }

                        //chiudo il blocco BUNDLE_ARRAY
                        if(node4.type == 'BUNDLE_ARRAY'){
                          let ramo: ramo = {
                            type: 'BUNDLE_ARRAY_STOP',
                            description: 'STOP',
                            bundles: bundles4
                          };
                          temp.push(ramo);
                        }

                      }else{
                        node4.key = keyRebuild + '|' + node4.key + '!F' + '!' + g;
                        temp.push(node4);
                      }
                    }

                    //chiudo il blocco BUNDLE_ARRAY
                    if(node3.type == 'BUNDLE_ARRAY'){
                      let ramo: ramo = {
                        type: 'BUNDLE_ARRAY_STOP',
                        description: 'STOP',
                        bundles: bundles3
                      };
                      temp.push(ramo);
                    }

                  }else{
                    node3.key = keyRebuild + '|' + node3.key + '!F' + '!' + f;
                    temp.push(node3);
                  }
                }

                //chiudo il blocco BUNDLE_ARRAY
                if(node2.type == 'BUNDLE_ARRAY'){
                  let ramo: ramo = {
                    type: 'BUNDLE_ARRAY_STOP',
                    description: 'STOP',
                    bundles: bundles2
                  };
                  temp.push(ramo);
                }

              }else{
                node2.key = keyRebuild + '|' + node2.key + '!F' + '!' + k;
                temp.push(node2);
              }
            }

            //chiudo il blocco BUNDLE_ARRAY
            if(node1.type == 'BUNDLE_ARRAY'){
              let ramo: ramo = {
                type: 'BUNDLE_ARRAY_STOP',
                description: 'STOP',
                bundles: bundles1
              };
              temp.push(ramo);
            }

          }else{
            node1.key = keyRebuild + '|' + node1.key + '!F' + '!' + j;
            temp.push(node1);
          }
        }

        //chiudo il blocco BUNDLE_ARRAY
        if(node.type == 'BUNDLE_ARRAY'){
          let ramo: ramo = {
            type: 'BUNDLE_ARRAY_STOP',
            description: 'STOP',
            bundles: bundles0
          };
          temp.push(ramo);
        }

      }else{
        node.styleNode = '';
        node.key = node.key + '!F' + '!' + i;
        temp.push(node);
      }
    }

    console.log('node [DOPO] -------------------------> ', temp);
    return temp;
  }


  _buildBundlesArray(tree: any) {
    //console.log('sotto_node [PRIMA]-------------------------> ', tree);
    var sottoTree = [];

    for (let i = 0; i < tree.length; i++) {
      //se ha figli
      var node: any;
      node = tree[i];

      if (node.nestedProperties) {

        for (let j = 0; j < node.nestedProperties.length; j++) {
          var node1: any;
          node1 = node.nestedProperties[j];

          if (node1.nestedProperties) {

            for (let k = 0; k < node1.nestedProperties.length; k++) {
              var node2: any;
              node2 = node1.nestedProperties[k];

              if (node2.nestedProperties) {

                for (let f = 0; f < node2.nestedProperties.length; f++) {
                  var node3: any;
                  node3 = node2.nestedProperties[f];

                  if (node3.nestedProperties) {

                    for (let g = 0; g < node3.nestedProperties.length; g++) {
                      var node4: any;
                      node4 = node3.nestedProperties[g];

                      if (node4.nestedProperties) {

                        for (let h = 0; h < node4.nestedProperties.length; h++) {
                          var node5: any;
                          node5 = node4.nestedProperties[h];

                          if (node5.nestedProperties) {

                            //...

                          }else{
                            sottoTree.push(node5);
                          }
                        }
                      }else{
                        sottoTree.push(node4);
                      }
                    }
                  }else{
                    sottoTree.push(node3);
                  }
                }
              }else{
                sottoTree.push(node2);
              }
            }
          }else{
            sottoTree.push(node1);
          }
        }
      }else{
        node.styleNode = '';
        sottoTree.push(node);
      }
    }

    //console.log('sotto_node [DOPO] -------------------------> ', sottoTree);
    return sottoTree;
  }

  callIteratively_() {

    var tree = this.application.managedProperties;
    console.log('node [PRIMA]-------------------------> ', tree);
    var temp = [];

    interface ramo {
      type: string;
      description: string;
    }
    var bunbleArray = 0;
    var bundleArray_x = 1;//fattore moltiplicativo
    var bundleArray_x_max = 2;//fattore moltiplicativo

    for (let i = 0; i < tree.length; i++) {
      //se ha figli
      var node: any;
      node = tree[i];

      if (node.nestedProperties) {
        //inseriscimi nodo ramo
        let ramo: ramo = {
          type: 'BUNDLE',
          description: node.description
        };
        temp.push(ramo);
        //in particolare verifico se BUNDLE_ARRAY, in caso ripeto il BULBLE filgio per bundleArray_x volte
        bundleArray_x = 1;
        if(node.type == 'BUNDLE_ARRAY'){
          bunbleArray += 1;
          bundleArray_x = bundleArray_x_max;
        }

        for (let x1 = bundleArray_x; x1 > 0; x1--) {
          for (let j = 0; j < node.nestedProperties.length; j++) {

            var node1: any;
            node1 = node.nestedProperties[j];

            if (node1.nestedProperties) {
              //inseriscimi nodo ramo
              let ramo1: ramo = {
                type: 'BUNDLE',
                description: node1.description
              };
              temp.push(ramo1);
              //in particolare verifico se BUNDLE_ARRAY, in caso ripeto il BULBLE filgio per bundleArray_x volte
              bundleArray_x = 1;
              if(node1.type == 'BUNDLE_ARRAY'){
                bunbleArray += 1;
                bundleArray_x = bundleArray_x_max;
              }

              for (let x2 = bundleArray_x; x2 > 0; x2--) {
                for (let k = 0; k < node1.nestedProperties.length; k++) {

                  var node2: any;
                  node2 = node1.nestedProperties[k];

                  if (node2.nestedProperties) {
                    //inseriscimi nodo ramo
                    let ramo2: ramo = {
                      type: 'BUNDLE',
                      description: node2.description
                    };
                    temp.push(ramo2);
                    //in particolare verifico se BUNDLE_ARRAY, in caso ripeto il BULBLE filgio per bundleArray_x volte
                    bundleArray_x = 1;
                    if(node2.type == 'BUNDLE_ARRAY'){
                      bunbleArray += 1;
                      bundleArray_x = bundleArray_x_max;
                    }

                    for (let x3 = bundleArray_x; x3 > 0; x3--) {
                      for (let f = 0; f < node2.nestedProperties.length; f++) {

                        var node3: any;
                        node3 = node2.nestedProperties[f];

                        if (node3.nestedProperties) {
                          let ramo3: ramo = {
                            type: 'BUNDLE',
                            description: node3.description
                          };
                          temp.push(ramo3);
                          //in particolare verifico se BUNDLE_ARRAY, in caso ripeto il BULBLE filgio per bundleArray_x volte
                          bundleArray_x = 1;
                          if(node3.type == 'BUNDLE_ARRAY'){
                            bunbleArray += 1;
                            bundleArray_x = bundleArray_x_max;
                          }

                          for (let x4 = bundleArray_x; x4 > 0; x4--) {
                            for (let g = 0; g < node3.nestedProperties.length; g++) {

                              var node4: any;
                              node4 = node3.nestedProperties[g];

                              if (node4.nestedProperties) {
                                //inseriscimi cmq il nodo per stampare instesazione sottogruppo
                                let ramo4: ramo = {
                                  type: 'BUNDLE',
                                  description: node4.description
                                };
                                temp.push(ramo4);
                                //in particolare verifico se BUNDLE_ARRAY, in caso ripeto il BULBLE filgio per bundleArray_x volte
                                bundleArray_x = 1;
                                if(node4.type == 'BUNDLE_ARRAY'){
                                  bunbleArray += 1;
                                  bundleArray_x = bundleArray_x_max;
                                }

                                for (let x4 = bundleArray_x; x4 > 0; x4--) {
                                  for (let h = 0; h < node4.nestedProperties.length; h++) {

                                    var node5: any;
                                    node5 = node4.nestedProperties[h];

                                    if (node5.nestedProperties) {
                                      //inseriscimi cmq il nodo per stampare instesazione sottogruppo
                                      let ramo5: ramo = {
                                        type: 'BUNDLE',
                                        description: node5.description
                                      };
                                      temp.push(ramo5);
                                      //in particolare verifico se BUNDLE_ARRAY, in caso ripeto il BULBLE filgio per bundleArray_x volte
                                      bundleArray_x = 1;
                                      if(node5.type == 'BUNDLE_ARRAY'){
                                        bunbleArray += 1;
                                        bundleArray_x = bundleArray_x_max;
                                      }

                                      //...

                                    }else{
                                      temp.push(node5);
                                    }
                                  }
                                }//end for x5

                              }else{
                                temp.push(node4);
                              }
                            }
                          }//end for x4

                        }else{
                          temp.push(node3);
                        }
                      }
                    }//end for x3

                  }else{
                    temp.push(node2);
                  }
                }
              }//end for x2

            }else{
              temp.push(node1);
            }
          }

        }//end for x1


      }else{
        node.styleNode = '';
        temp.push(node);
      }
    }

    console.log('node [DOPO] -------------------------> ', temp);
    console.log('---------->>>>>> ', bunbleArray);
    return temp;
  }

  addPermission() {
    if(!this.applicationPolicy?.permissions) {
      this.applicationPolicy = {
        permissions: []
      }
    }
    this.applicationPolicy.permissions.push({'permission': this.configurationForm?.controls.newPermissionKey.value});
    this.applicationPermissionsFormArray.push(this.formBuilder.control(this.configurationForm?.controls.newPermissionPolicy.value));
    this.configurationForm.patchValue({
      newPermissionKey: null,
      newPermissionPolicy: null
    });
  }

  save() {
    let managedConfigurations = [];
    for (let i = 0; i < this.managedConfigurationsFormArray?.value?.length; i++) {
      if(this.application.managedProperties[i].key){//salto i nodi padre i BUNDLE
        managedConfigurations.push({
          "key": this.application.managedProperties[i].key,
          "value": this.managedConfigurationsFormArray?.value[i]
        });
      }
    }
    let permissions = [];
    for (let i = 0; i < this.applicationPermissionsFormArray?.value?.length; i++) {
      if(this.applicationPermissionsFormArray?.value[i]) {
        permissions.push({
          "permission": this.applicationPolicy.permissions[i].permission,
          "policy": this.applicationPermissionsFormArray?.value[i]
        });
      }
    }

    console.log('____________ONSAVE____________:managedConfigurations: ><<<<<<>>>>>>> ', managedConfigurations);

    const application: IApplicationPolicy = {
      index: this.applicationPolicyIndex,
      packageName: this.application.packageName,
      installType: this.configurationForm?.controls.installType.value,
      autoUpdateMode: this.configurationForm?.controls.autoUpdateMode.value,
      minimunVersionCode: this.configurationForm?.controls.minimunVersionCode.value,
      defaultPermissionPolicy: this.configurationForm?.controls.defaultPermissionPolicy.value,
      managedConfigurations: managedConfigurations,
      permissions: permissions,
      idCompany: this.settings.getCompany().code
    };
    this.onSaved.emit(application);
  }

  onShowManagedConfigurations(): void {
    this.showManagedConfigurations = !this.showManagedConfigurations;
  }

}