<button type="button" class="btn px-2 shadow-sm w-100"
    [title]="title"
    [ngClass]="(outline ? styles[type]?.classOutline : styles[type]?.class) + ' ' + (customClass ? customClass : '')"
    [ngStyle]="type === s1ButtonType.SectionHeader && { 'width': '7rem', 'display': 'flex', 'justify-content': 'space-between' }"
    [disabled]="disabled"
    [hidden]="hidden"
    (click)="click()">
    <i *ngIf="!loading && !noIcon" class="{{ icon ? icon : styles[type]?.icon }}"></i> 
    <i *ngIf="loading" class="fas fa-spinner fa-spin"></i>
    <span class={{setLabelMargin}} *ngIf="!onlyIcon"> {{ (label ? label : styles[type]?.label) | translate }} </span>
    <span *ngIf="!onlyIcon && selValue !== null"> {{ selValue }} </span>
</button>