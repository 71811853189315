import Debug from 'debug';
import Device from './Device.js';
const debug = Debug('cbio.devices');
export default ((api, sockets) => ({
  get: async (id, query) => {
    debug('get()', query);
    return new Device(api, sockets, await api.fetch(Device.url({
      id
    }), query));
  },
  list: async query => {
    debug('list()', query);
    const devices = await api.list(Device.url(), query);
    return devices.map(device => new Device(api, sockets, device));
  }
}));
export { Device };