import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 's1-input-label',
  templateUrl: './s1-input-label.component.html',
  styleUrls: ['./s1-input-label.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class S1InputLabel {

  @Input() label: string = '';
  @Input() grouped: boolean = true;
  @Input() styleClass: string = '';
  @Input() required: boolean = false;

}
