
<div class="row mt-1 px-4">
    <div class="col px-0">
        <s1-card>
            <div class="row text-primary d-flex align-items-center">
                <h4 class="offset-4 col-4 text-center mt-2">{{ 'dashboard.summary.units_total' | translate }} {{totalDevices}}</h4>
                <div class="col-4 text-right">
                    <s1-button class="mr-2" [type]="s1ButtonType.Refresh" [onlyIcon]="false" [outline]="true" (onClick)="refresh()" [loading]="loading" [disabled]="loading"></s1-button>
                </div>
            </div>
        </s1-card>
    </div>
</div>


<div class="row px-4">
    <div class="col-12 col-md-6 col-lg px-0 mr-0 mr-lg-2 mb-2 mb-lg-0">
        <mdm-dashboard-summary-card [config]="unitsConnectedConfig" [plotType]="unitsConnectedPlotType" (onDetailPlotLink)="goToUnits($event)"></mdm-dashboard-summary-card>
    </div>
    <div class="col-12 col-md-6 col-lg px-0 mr-0 mr-lg-2 mb-2 mb-lg-0">
        <mdm-dashboard-summary-card [config]="profilesAppliedConfig" [plotType]="profilesAppliedPlotType" (onDetailPlotLink)="goToUnits($event)"></mdm-dashboard-summary-card>
    </div>
    <div class="col-12 col-md-6 col-lg px-0 mr-0 mr-lg-2 mb-2 mb-lg-0">
        <mdm-dashboard-summary-card [config]="osAppliedConfig" [plotType]="osAppliedPlotType" (onDetailPlotLink)="goToUnits($event)"></mdm-dashboard-summary-card>
    </div>
    <div class="col-12 col-md-6 col-lg px-0">
        <mdm-dashboard-summary-card [config]="batteryHealthConfig" [plotType]="batteryHealthPlotType" (onDetailPlotLink)="goToUnits($event)"></mdm-dashboard-summary-card>
    </div>
</div>

<div class="row mt-2 px-4">
    <div class="col px-0">
        <mdm-dashboard-profile-card [summary]="profilesSummary" (onDetailPlotLink)="goToUnits($event)"></mdm-dashboard-profile-card>
    </div>
</div>

<div class="row mt-2">
    <div class="d-flex align-items-center justify-content-center col-12 text-center text-primary font-weight-bold">
        <div style="cursor: pointer;" (click)="getSitesUnitsInfo()">
            <span>{{ (showMore ? 'dashboard.show_less' : 'dashboard.show_more') | translate }}</span>
            <em class="ml-2 fas" [ngClass]="showMore ? 'fa-angle-up' : 'fa-angle-down'"></em>
        </div>
    </div>
</div>

<div *ngIf="showMore">
    <div class="row mt-2 px-4" *ngIf="totalDevices>0">
        <div class="col px-0">
            <mdm-dashboard-summary-card [config]="sitesUnitsConnectedConfig" [plotType]="sitesUnitsConnectedPlotType" [hasModal]="sitesUnitsConnectedHasModal" (onDetailPlotLink)="goToUnits($event, true)" (onModalLink)="goToUnitsFromModal($event)"></mdm-dashboard-summary-card>
        </div>
    </div>
    <div class="row mt-2 px-4" *ngIf="!zeroAppliedProfiles">
        <div class="col px-0">
            <mdm-dashboard-summary-card [config]="sitesProfilesAppliedConfig" [plotType]="sitesProfilesAppliedPlotType" [hasModal]="sitesProfilesAppliedHasModal" (onDetailPlotLink)="goToUnits($event, true)" (onModalLink)="goToUnitsFromModal($event)"></mdm-dashboard-summary-card>
        </div>
    </div>
    <div class="row mt-2 px-4" *ngIf="!zeroOs">
        <div class="col px-0">
            <mdm-dashboard-summary-card [config]="sitesOsAppliedConfig" [plotType]="sitesOsAppliedPlotType" [hasModal]="sitesOsAppliedHasModal" (onDetailPlotLink)="goToUnits($event,true)" (onModalLink)="goToUnitsFromModal($event)"></mdm-dashboard-summary-card>
        </div>
    </div>
    <div class="row mt-2 px-4" *ngIf="!zeroLevelBattery && !showBatteryHealthPlot">
        <div class="col px-0">
            <mdm-dashboard-summary-card [config]="sitesbatteryLevelConfig" [plotType]="sitesbatteryLevelPlotType" [hasPages]="!zeroHealthBattery" [hasModal]="sitesbatteryLevelHasModal" (onNextPlotLink)="showBatteryHealthPlot=!showBatteryHealthPlot" (onDetailPlotLink)="goToUnits($event, true)" (onModalLink)="goToUnitsFromModal($event)"></mdm-dashboard-summary-card>
        </div>
    </div>
    <div class="row mt-2 px-4" *ngIf="!zeroHealthBattery && showBatteryHealthPlot">
        <div class="col px-0">
            <mdm-dashboard-summary-card [config]="sitesbatteryHealthConfig" [plotType]="sitesbatteryHealthPlotType" [hasPages]="!zeroLevelBattery" [hasModal]="sitesbatteryHealthHasModal" (onNextPlotLink)="showBatteryHealthPlot=!showBatteryHealthPlot" (onDetailPlotLink)="goToUnits($event, true)" (onModalLink)="goToUnitsFromModal($event)"></mdm-dashboard-summary-card>
        </div>
    </div>
</div>
