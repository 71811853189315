<ng-container *ngIf="template == 'units'">

  <div class="d-flex flex-column flex-md-row">
    <div class="col-12 col-md-2 pl-0 pl-md-auto">
      <p class="mb-1">
        <em *ngIf="!ae" class="fa mr-1 fa-tablet-alt"></em>
        <em *ngIf="ae" class="mr-1 fa-google fab"></em>
        <strong class="mr-1">{{ unit?.code }}</strong>
      </p>
      <p class="mb-1" *ngIf="!isDisabled">
        <span><em class="fas fa-globe"></em> {{ unit?.ipaddress || 'no ip' }} - <em class="fab fa-android"></em> {{ unit?.osRelease || "base.notassigned" | translate }}</span>
      </p>
      <p class="mb-0" *ngIf="unit?.nameRegion">
        <em class="fa mr-1 fa fa-map"></em>
        <span class="mr-1">{{ unit?.nameRegion }}</span>
        
        <ng-container *ngIf="unit?.nameSite">
          <em class="fa ml-2 mr-1 fa fa-building"></em>
          <span class="mr-1">{{ unit?.nameSite }}</span>
        </ng-container>
      </p>
      <p class="mt-0" *ngIf="unit?.nameGroup">
        <em class="fa mt-1 mr-1 fa fa-coins"></em>
        <span class="mr-1">{{ unit?.nameGroup }}</span>
      </p>
    </div>

    <div class="col-12 col-md-3 pl-0 pl-md-auto">
      <p class="mb-1 ">
        <strong>{{ unit?.nameBrand }} - {{ unit?.nameModel }}</strong>
      </p>
      <p class="col-12 pl-0 pl-md-auto text-break">
        <span>{{ unit?.fingerprint }}</span>
      </p>
    </div>

    <div class="col-12 mb-2 col-md-1 pl-0 pl-md-auto mb-sm-1 d-flex flex-row flex-md-column align-items-baseline align-items-md-center justify-content-between justify-content-md-start">
      <div class="mb-1 text-center">
        <b [ngClass]="stylizeStatus(unit?.status)">{{unit?.status}}</b>
      </div>
      <div class="mb-1 text-center" *ngIf="!isDisabled">
        <battery-icon class="ml-2" [percentage]="unit?.batteryPerc"></battery-icon>
      </div>
      <div class="mb-1 text-center" *ngIf="!ae && unit?.appRelease">
        <i [ngClass]="unit?.deprecated ? 'ml-3 fas fa-exclamation-triangle text-warning' : 'app-release-icon'"></i>
        <span class="ml-1" [ngClass]="{'text-warning font-weight-bold': unit?.deprecated}">{{ unit?.appRelease}}</span>
      </div>
    </div>

    <div class="col-12 col-md-2 pl-0 pl-md-auto mb-2">
      <div class="mb-1 font-small d-flex d-md-block justify-content-between" *ngIf="!isDisabled">
        <div class="text-center">
          <em title="{{'units.list.table.columns.tsLastEvent' | translate}}" class="icon-bell mr-2"></em>
          {{ unit?.tsLastEvent | s1ReadableDate: true  }}
          <span *ngIf="!unit?.tsLastEvent">no event</span>
        </div>
        <div class="text-center" *ngIf="unit?.tsLastEvent">
          <span *ngIf="unit?.secondsSinceLastEvent" class="small">{{ unit?.secondsSinceLastEvent | timestampToTime}} {{'base.ago' | translate}}</span>
        </div>
      </div>
      <div class="font-small d-flex d-md-block justify-content-between" *ngIf="!isDisabled">
        <div class="text-center">
          <em title="{{'units.list.table.columns.tsLastStatus' | translate}}" class="icon-feed mr-2"></em>
          {{ unit?.tsLastStatus | s1ReadableDate: true}}
          <span class="small ml-4" *ngIf="!unit?.tsLastStatus">no status</span>
        </div>
        <div class="text-center" *ngIf="unit?.tsLastStatus">
          <span *ngIf="unit?.secondsSinceLastStatus" class="small" [ngClass]="stylizeSinceLastStatus(unit?.secondsSinceLastStatus)">{{ unit?.secondsSinceLastStatus | timestampToTime: '48' }} {{'base.ago' | translate}}</span>
        </div>
      </div>
      <div *ngIf="isDisabled && unit?.tsDisabling">
        <div>
          <em title="{{'units.list.table.columns.tsDisabling' | translate}}" class="fas fa-ban mr-2"></em>
          {{ unit?.tsDisabling | s1ReadableDate: true  }}
        </div>
      </div>
    </div>

    <div class="col-12 col-md-2 text-left pl-0 pl-md-auto mt-2 mt-md-0 d-flex d-md-block justify-content-between">
      <ng-container *ngIf="!ae">
        <!-- not Android Enterprise-->
        <div class="mb-2" *ngIf="unit?.idSettingsProfile || unit?.idSettingsProfilePending">
          <em class="ml-0 ml-md-4 mr-1 icon-smartilio icon-settings"
            [ngClass]="stylizeProfile(unit?.idSettingsProfile, unit?.idSettingsProfilePending, unit?.statusSettingsProfile)"
            (click)="openProfileSettings()">
          </em>
          <span [ngClass]="stylizeProfile(unit?.idSettingsProfile, unit?.idSettingsProfilePending, unit?.statusSettingsProfile)"
            (click)="openProfileSettings()">{{'profiles.types.settings' | translate}}
          </span>
          <em *ngIf="unit?.idSettingsProfilePending" class="ml-2 fas fa-clock pointer text-warning" (click)="openProfileSettingsPending()"></em>
        </div>

        <div class="mb-2" *ngIf="unit?.idNetworkProfile || unit?.idNetworkProfilePending">
          <em class="ml-0 ml-md-4 mr-1 icon-smartilio icon-network"
            [ngClass]="stylizeProfile(unit?.idNetworkProfile, unit?.idNetworkProfilePending, unit?.statusNetworkProfile)"
            (click)="openProfileNetwork()">
          </em>
          <span [ngClass]="stylizeProfile(unit?.idNetworkProfile, unit?.idNetworkProfilePending, unit?.statusNetworkProfile)"
            (click)="openProfileNetwork()">{{'profiles.types.network' | translate}}
          </span>
          <em *ngIf="unit?.idNetworkProfilePending" class="ml-2 fas fa-clock pointer text-warning" (click)="openProfileNetworkPending()"></em>
        </div>

        <div class="mb-2" *ngIf="unit?.idAssetsProfile || unit?.idAssetsProfilePending">
          <em class="ml-0 ml-md-4 mr-1 icon-smartilio icon-asset"
            [ngClass]="stylizeProfile(unit?.idAssetsProfile, unit?.idAssetsProfilePending, unit?.statusAssetsProfile)"
            (click)="openProfileAsset()">
          </em>
          <span [ngClass]="stylizeProfile(unit?.idAssetsProfile, unit?.idAssetsProfilePending, unit?.statusAssetsProfile)"
            (click)="openProfileAsset()">{{'profiles.types.asset' | translate}}
          </span>
          <em *ngIf="unit?.idAssetsProfilePending" class="ml-2 fas fa-clock pointer text-warning" (click)="openProfileAssetPending()"></em>
        </div>

      </ng-container>
    </div>
    
    <div class="col-12 col-md-auto pl-0 pl-md-auto mt-2 mt-md-0 d-flex d-md-block justify-content-between">
      <div class="mb-2 pointer text-primary" (click)="showDetail()">
        <em class="mr-1 fas fa-info-circle"></em>
        <span>{{'units.list.table.columns.moreinfo' | translate}}</span>
      </div>
      <ng-container *ngIf="!ae">
        <div class="mb-2 pointer" (click)="openFileSystem()" *ngIf="!isDisabled">
          <em class="mr-1 icon-smartilio icon-filesystem"></em>
          <span>{{'units.list.table.columns.filesystem' | translate}}</span>
        </div>
        <!-- <div class="mb-2 pointer" (click)="openAssetsModal()">
          <em class="mr-1 fas fa-puzzle-piece"></em>
          <span>{{'units.list.table.columns.assets' | translate}}</span>
        </div> -->
        <div *ngIf="!isOperatoreRO && token" class="mb-2 pointer" (click)="openCobrowseModal()">
          <em class="mr-1 fas fa-laptop"></em>
          <span>{{'units.detail.cobrowse' | translate}}</span>
        </div>
        <div *ngIf="!isDisabled" class="mb-0 pointer text-danger" (click)="openAskDisableModal()">
          <em class="mr-1 far fa-stop-circle"></em>
          <span>{{'units.list.table.columns.disable' | translate}}</span>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row" *ngIf="isDisabled">
    <div class="col">
      <p class="mb-1">
        <em class="fas mr-2 fa-exclamation-triangle" style="color: red;" *ngIf="unit?.failure"></em>
        <em class="fas mr-1 fa-ban"></em>
        <strong class="mr-1">{{ 'units.list.table.columns.reasonDisabling' | translate}}:</strong> {{unit?.disablingReason}}
      </p>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="template == 'profiles'">
  <div class="row">
    
    <div class="col-3 text-left">
      <ng-container *ngIf="!modal">
        <p class="pointer text-primary" (click)="goToUnits(unit)">
          <u>
            <em class="fa mr-1 fa-tablet-alt"></em>
            <strong class="mr-1">{{ unit?.code }}</strong>
          </u>
        </p>
      </ng-container>
      <ng-container *ngIf="modal">
        <p>
          <em class="fa mr-1 fa-tablet-alt"></em>
          <strong class="mr-1">{{ unit?.code }}</strong>
        </p>
      </ng-container>

      <p class="mb-0" *ngIf="unit?.nameRegion">
        <em class="fa mr-1 fa fa-map"></em>
        <span class="mr-1">{{ unit?.nameRegion }}</span>
        
        <ng-container *ngIf="unit?.nameSite">
          <em class="fa ml-2 mr-1 fa fa-building"></em>
          <span class="mr-1">{{ unit?.nameSite }}</span>
        </ng-container>
      </p>
      <p class="mt-0" *ngIf="unit?.nameGroup">
        <em class="fa mt-1 mr-1 fa fa-coins"></em>
        <span class="mr-1">{{ unit?.nameGroup }}</span>
      </p>


      <p class="mb-0 mt-1" *ngIf="unit.profileStatus">
        <span>{{ 'fields.input.profile' | translate }} <b [ngClass]="stylizeProfileStatus(unit?.profileStatus)">{{ unit?.profileStatus }}</b> <span *ngIf="unit.profileForced" class="ml-2 text-primary font-weight-bold">[{{ 'base.forced' | translate }}]</span></span>
      </p>
    </div>

    <div class="col-6 text-left">
      <p>
        <strong>{{ unit?.nameBrand }} - {{ unit?.nameModel }}</strong>
      </p>
      <p class="mb-1">
        <span>{{ unit?.fingerprint }}</span>
      </p>
      <ng-container *ngIf="!isOperatoreRO && !ae && unit?.profileStatus ===  profileStatus.ASSIGNED">
        <s1-button [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_canceled'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(unit)"></s1-button>  
      </ng-container>
      <ng-container *ngIf="!isOperatoreRO && !ae && unit?.profileStatus ===  profileStatus.DOWNLOAD">
        <s1-button [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_interrupted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(unit)"></s1-button>  
      </ng-container>
      <ng-container *ngIf="!isOperatoreRO && !ae && unit?.profileStatus ===  profileStatus.SENT">
        <s1-button [type]="s1ButtonType.Delete" [icon]="'mr-2 fas fa-stop'" [label]="'profiles.distributions.all.set_profile_status_in_aborted'" [onlyIcon]="false" (onClick)="askChangeProfileStatus(unit)"></s1-button>  
      </ng-container>
    </div>

    <div class="col-2">
      <div class="mb-1 text-center">
        <b [ngClass]="stylizeStatus(unit?.status)">{{unit?.status}}</b>
      </div>
      <div class="mb-1 text-center" *ngIf="unit?.appRelease">
        <i [ngClass]="unit?.deprecated ? 'ml-3 fas fa-exclamation-triangle text-warning' : 'app-release-icon'"></i>
        <span class="ml-1" [ngClass]="{'text-warning font-weight-bold': unit?.deprecated}">{{ unit?.appRelease}}</span>
      </div>
      <div class="mb-0 text-center" *ngIf="unit?.osRelease">
        <span><em class="fa-lg fab fa-android text-info"></em> <span class="ml-1">{{ unit?.osRelease || "base.notassigned" | translate }}</span></span>
      </div>
    </div>

    <div class="col-1" *ngIf="showButtons">
      <s1-button  *ngIf="!isOperatoreRO" [type]="s1ButtonType.Delete" [onlyIcon]="true" [outline]="true" (onClick)="askRemove(unit)"></s1-button>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="template == 'assets'">

  <div class="row">
    <div class="col-3">
      <p class="mb-1">
        <em class="fa mr-1 fa-tablet-alt"></em>
        <strong class="mr-1">{{ unit?.code }}</strong>
      </p>
      <p class="mb-1" *ngIf="!isDisabled">
        <span><em class="fas fa-globe"></em> {{ unit?.ipaddress || 'no ip' }} - <em class="fab fa-android"></em> {{ unit?.osRelease || "base.notassigned" | translate }}</span>
      </p>

      <p class="mb-0" *ngIf="unit?.nameRegion">
        <em class="fa mr-1 fa fa-map"></em>
        <span class="mr-1">{{ unit?.nameRegion }}</span>
        
        <ng-container *ngIf="unit?.nameSite">
          <em class="fa ml-2 mr-1 fa fa-building"></em>
          <span class="mr-1">{{ unit?.nameSite }}</span>
        </ng-container>
      </p>
      <p class="mt-0" *ngIf="unit?.nameGroup">
        <em class="fa mt-1 mr-1 fa fa-coins"></em>
        <span class="mr-1">{{ unit?.nameGroup }}</span>
      </p>
    </div>

    <div class="col-4">
      <p class="mb-1">
        <strong>{{ unit?.nameBrand }} - {{ unit?.nameModel }}</strong>
      </p>
      <p>
        <span>{{ unit?.fingerprint }}</span>
      </p>
    </div>

    <div class="col-2">
      <div class="mb-1 text-center">
        <b [ngClass]="stylizeStatus(unit?.status)">{{unit?.status}}</b>
      </div>
      <div class="mb-1 text-center" *ngIf="!isDisabled">
        <battery-icon class="ml-2" [percentage]="unit?.batteryPerc"></battery-icon>
      </div>
      <div class="mb-1 text-center" *ngIf="!ae && unit?.appRelease">
        <i [ngClass]="unit?.deprecated ? 'ml-3 fas fa-exclamation-triangle text-warning' : 'app-release-icon'"></i>
        <span class="ml-1" [ngClass]="{'text-warning font-weight-bold': unit?.deprecated}">{{ unit?.appRelease}}</span>
      </div>
    </div>

    <div class="col-3">
      <div class="mb-1 font-small" *ngIf="!isDisabled">
        <div>
          <em title="{{'units.list.table.columns.tsLastEvent' | translate}}" class="icon-bell mr-2"></em>
          {{ unit?.tsLastEvent | s1ReadableDate: true  }}
          <span *ngIf="!unit?.tsLastEvent">no event</span>
        </div>
        <div class="text-center" *ngIf="unit?.tsLastEvent">
          <span *ngIf="unit?.secondsSinceLastEvent" class="small">{{ unit?.secondsSinceLastEvent | timestampToTime}} {{'base.ago' | translate}}</span>
        </div>
      </div>
      <div class="font-small" *ngIf="!isDisabled">
        <div>
          <em title="{{'units.list.table.columns.tsLastStatus' | translate}}" class="icon-feed mr-2"></em>
          {{ unit?.tsLastStatus | s1ReadableDate: true}}
          <span class="small ml-4" *ngIf="!unit?.tsLastStatus">no status</span>
        </div>
        <div class="text-center" *ngIf="unit?.tsLastStatus">
          <span *ngIf="unit?.secondsSinceLastStatus" class="small" [ngClass]="stylizeSinceLastStatus(unit?.secondsSinceLastStatus)">{{ unit?.secondsSinceLastStatus | timestampToTime: '48' }} {{'base.ago' | translate}}</span>
        </div>
      </div>
      <div *ngIf="isDisabled && unit?.tsDisabling">
        <div>
          <em title="{{'units.list.table.columns.tsDisabling' | translate}}" class="fas fa-ban mr-2"></em>
          {{ unit?.tsDisabling | s1ReadableDate: true  }}
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="isDisabled">
    <div class="col">
      <p class="mb-1">
        <em class="fas mr-1 fa-ban"></em>
        <strong class="mr-1">{{ 'units.list.table.columns.reasonDisabling' | translate}}:</strong> {{unit?.disablingReason}}
      </p>
    </div>
  </div>

</ng-container>