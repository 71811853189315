<s1-input-select class="col-6 mb-2 fix-margin"
                 [readonly]="readonly"
                 [control]="control"
                 [itemsList]="options"
                 [bindAllObject]="bindAllObject"
                 [label]="label"
                 [required]="required"
                 [clearable]="clearable"
                 [labelGrouped]="false"
                 (onSelectClear)="cleared()"
                 (onSelectChange)="changed($event)"
                 ></s1-input-select>
