import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface IS1InputSelectItem {
  code: string | number;
  label: string;
  disabled?: boolean;
}

@Component({
  selector: 's1-input-select',
  templateUrl: './s1-input-select.component.html',
  styleUrls: ['./s1-input-select.component.scss']
})
export class S1InputSelect implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';
  @Input() labelGrouped: boolean = true;
  @Input() readonly: boolean = false;
  @Input() itemsList: IS1InputSelectItem[];
  @Input() itemDescpription: string;
  @Input() bindAllObject: boolean = true;
  @Input() clearable: boolean = true;
  @Input() placeholder: string = '';
  @Input() required: boolean = false;

  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  checkIsValid(): boolean {
    return this.control.valid || !this.control.touched;
  }

  cleared() {
    this.onSelectClear.emit();
  }

  changed(event) {
    this.onSelectChange.emit(event);
  }

}
