<div class="w-100 d-flex justify-content-between align-items-center" [style.visibility]="table.totalData > 0 ? 'visible' : 'hidden'">
  <pagination [style.visibility]="table.totalData > table.pagination.page.itemsPerPage ? 'visible' : 'hidden'"
    class="pagination-sm w-25 w-lg-100"
    [(ngModel)]="table.pagination.page.page"
    [totalItems]="table.totalData"
    [itemsPerPage]="table.pagination.page.itemsPerPage"
    [maxSize]="table.pagination.maxSize"
    [boundaryLinks]="true"
    [rotate]="false"
    (pageChanged)="changePage($event)"
    (numPages)="table.pagination.numPages = $event"
    [firstText]="'s1.pagination.first' | translate"
    [previousText]="'s1.pagination.previous' | translate"
    [nextText]="'s1.pagination.next' | translate"
    [lastText]="'s1.pagination.last' | translate">
  </pagination>

  <div class="d-flex align-items-center">
    <h5 class="mb-0 mb-lg-auto">
      {{ (table.pagination.page.page - 1) * table.pagination.page.itemsPerPage + 1 }} - 
      {{ table.totalData > table.pagination.page.page * table.pagination.page.itemsPerPage ? table.pagination.page.page * table.pagination.page.itemsPerPage : table.totalData }}  
      {{ 's1.pagination.of' | translate }} {{ table.totalData }}
     </h5>
  </div>

  <div class="d-flex align-items-center">
    <span class="d-inline mr-2">{{ 's1.pagination.itemsPerPage' | translate }}</span>
    <select class="form-control w-auto d-inline" [ngModel]="table.pagination.page.itemsPerPage" (ngModelChange)="onChangeItemsPerPage($event)"> 
      <option *ngFor="let pageItems of pageItemsList; let i = index" [value]="pageItems">{{ pageItems }}</option>
    </select>
  </div>
</div>
