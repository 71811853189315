

<div class="row">
  
  <div class="col-2 media" *ngIf="profileType == 'all'">
    <div class="media-body">
      <div class="ml-3 mb-2" *ngIf="profile.profileType == 'Settings'">
        <em class="mr-1 icon-smartilio icon-settings "></em>
        <strong>{{'profiles.types.settings' | translate}}</strong>
      </div>
      <div class="ml-3 mb-2" *ngIf="profile.profileType == 'Network'">
        <em class="mr-1 icon-smartilio icon-network"></em>
        <strong>{{'profiles.types.network' | translate}}</strong>
      </div>
      <div class="ml-3 mb-2" *ngIf="profile.profileType == 'Asset'">
        <em class="mr-1 icon-smartilio icon-asset"></em>
        <strong>{{'profiles.types.asset' | translate}}</strong>
      </div>
      <div class="ml-3 mb-2" *ngIf="profile.profileType == 'Android Enterprise'">
        <em class="mr-1 icon-smartilio icon-android "></em>
        <strong>{{'profiles.types.ae' | translate}}</strong>
      </div>
    </div>
  </div>
  <div class="media" [ngClass]="profileType == 'all' ? 'col-4' : (profileType == 'os' ? 'col-5' : 'col-6')">
    <div class="media-body">
      <p><strong>{{ profile?.description }}</strong></p>
      <p class="mb-0">{{ profile?.profile }}</p>
    </div>  
  </div>
  <div class="col-1 media" *ngIf="profileType == 'os'">
    <div class="media-body text-center">
      <em class="mr-2 fa-2x fas fa-mobile-alt"></em>
      <em [ngClass]="iconAction(profile?.action)"></em>
      <p class="mb-0">{{ profile?.action | lowercase }}</p>
    </div>  
  </div>
  <div class="col-2 media">
    <div class="media-body text-center" [ngClass]="stylizeProgress(profile?.status)">
      <em [ngClass]="iconProgress(profile?.status)"></em>
      <p class="mb-0">{{ profile?.progress }}</p>
    </div>  
  </div>
  <div class="col-2 media">
    <div class="media-body text-left">
      <div class="mb-1">
        <em class="far fa-clock mr-2"></em>
        <span *ngIf="profile?.tsStart">{{ profile?.tsStart | s1ReadableDate: true  }}</span>
        <span *ngIf="!profile?.tsStart" class="text-muted">{{'base.tbd' | translate}}</span><br>
      </div>
      <div class="">
        <em class="fa fa-edit mr-2"></em>
        <span>{{ profile?.tsCreated | s1ReadableDate: true  }}</span><br>
      </div>
    </div>  
  </div>
  <div class="col-2 media">
    <div class="media-body text-right"> 
      <ng-container *ngIf="canEdit(profile?.tsStart) && !isOperatoreRO; else canView" >
        <s1-button class="mr-2" (onClick)="goToDetail()" [type]="s1ButtonType.Edit" [onlyIcon]="true" [outline]="true" [title]="'profiles.distributions.buttons.view' | translate"></s1-button>
        <s1-button (onClick)="askToDelete(profile)" [type]="s1ButtonType.Delete" [onlyIcon]="true" [outline]="true"></s1-button>
      </ng-container>
      <ng-template #canView>
        <s1-button class="mr-2" (onClick)="goToDetail()" [type]="s1ButtonType.View" [onlyIcon]="true" [outline]="true" [title]="'profiles.distributions.buttons.view' | translate"></s1-button>
        <s1-button *ngIf="!isOperatoreRO" [type]="s1ButtonType.Delete" [onlyIcon]="true" [outline]="true" [disabled]="true"></s1-button>
      </ng-template>
    </div>  
  </div>
</div>