import Debug from 'debug';
import Session from './Session.js';
const debug = Debug('cbio.sessions');
export default ((api, sockets) => ({
  create: async (resource = {}, query) => {
    debug('create()', resource, query);
    return new Session(api, sockets, await api.create(Session.url(), resource, query));
  },
  get: async (idOrCode, query) => {
    debug('get()', idOrCode, query);
    return new Session(api, sockets, await api.fetch(Session.url({
      id: idOrCode
    }), query));
  },
  list: async query => {
    debug('list()', query);
    const sessions = await api.list(Session.url(), query);
    return sessions.map(session => new Session(api, sockets, session));
  }
}));
export { Session };