import Debug from 'debug';
import SessionRecording from './SessionRecording.js';
const debug = Debug('cbio.recordings');
export default (api => ({
  get: async (id, query) => {
    debug('get()', id, query);
    return new SessionRecording(api, await api.fetch(SessionRecording.url({
      id
    }), query));
  },
  destroy: async (id, query) => {
    debug('destroy()', id, query);
    return new SessionRecording(api, await api.destroy(SessionRecording.url({
      id
    }), query));
  }
}));
export { SessionRecording };