import { UntypedFormGroup } from '@angular/forms';
import { IAsset } from './models';
import { S1Table } from '@app/s1';
import { IApplicationPolicy } from '../services/ae.service';

export enum ProfileType {
 settings = "SETTINGS",
 network = "NETWORK",
 fileManager = "FILEAPP"
}

export enum ProfileTypeName {
 settings = "Settings",
 network = "Network",
 fileManager = "Asset",
 androidEnterprise = "android_enterprise",
 os = "OS"
}

export interface IProfile {
  id: number,
  idType: number,
  codeType: string,
  descriptionType: string,
  hasChildren: boolean,
  hasAssignments: boolean,
  code: string,
  tsStart?: number,
  tsEnd?: number,
  description: string,
  selected: boolean,
  idCompany: number,
  nameCompany: string,
  idRegion: string,
  groupedRegionId?: number,
  groupedSiteId?: number
  groupedBrandId?: number,
  groupedGroupId?: number,
  groupedModelId?: number,
  hierarchicalConditionsVisibile?: boolean;
  codUnitConditionsVisibile?: boolean;
}
export interface IProfileGroupedBy {
  id: number,
  type: string,
  description: string,
  items?: IProfileGroupedBy,
  profiles?: IProfile[]
}

export interface IProfileType {
  id: number,
  code: string,
  name: string
}

interface IProfileBase {
  id?: number,
  idType?: number,
  nameType?: string,
  idUnit?: number,
  codeUnit?: string,
  idProfile?: number,
  nameProfile?: string
  viewPendingProfile?: boolean
  viewCanceledProfile?: any;
  //presenti in profile setting , ma non in unit profile setting
  code?: string,
  versionProfile?: string,
  description?: string,
  tsStart?: number,
  tsEnd?: number,
  idCompany: number,
  idRegion?: number,
  idSite?: number,
  idGroup?: number
}

export interface IProfileSetting extends IProfileBase {
  brightness: number,
  barsVisibility: boolean,
  barVisibilityTop: boolean;
  barVisibilityBottom: boolean;
  screentimeout: number,
  dailyReboot: boolean,
  dailyRebootTime: string,
  whenCharging: boolean;
  locale: string,
  timezone: string,
  history?: IProfileSetting[],
  assigned?: IProfileSetting[],
  canceled?: IProfileSetting[],
  tsActivation?: number,
  status?: string,
  idUnitProfile?: number,
  gpsInfo?: boolean,
  hasChildren?: boolean
}

export interface IProfileNetwork extends IProfileBase {
  connectImmediately: boolean,
  hidden: boolean,
  ssid: string,
  password: string,
  encryption: string,
  codeIpAssignmentStrategy: string,
  history?: IProfileNetwork[],
  assigned?: IProfileNetwork[],
  canceled?: IProfileNetwork[],
  status?: string,
  idUnitProfile?: number
}

export interface IProfileAsset extends IProfileBase {
  assets: IAsset[],
  history?: IProfileAsset[],
  assigned?: IProfileAsset[],
  canceled?: IProfileAsset[],
  status?: string,
  idUnitProfile?: number
}

export interface IProfileOS extends IProfileBase {
  os: IOS
}

export interface IOS {
  code?: string,
  versionAsset?: string,
  description: string,
  source: string,
  target: string,
  type: string
}

export interface IProfileAssignment {
  idProfile: number,
  id?: number,
  idCompany?: number,
  nameCompany?: string,
  idRegion?: number,
  nameRegion?: string,
  idSite?: number,
  nameSite?: string,
  idGroup?: number,
  nameGroup?: string,
  idBrand?: number,
  nameBrand?: string,
  idModel?: number,
  nameModel?: string,
  idUnit?: number,
  codUnit?: string,
  ipaddress?: string,
  btmac?: string,
  wifimac?: string,
  batterySerialNumber?: string,
  batteryPerc?: number,
  appRelease?: string,
  osRelease?: string,
  tsLastStatus?: number,
  tsLastEvent?: number,
  profileStatus?: ProfileStatus,
  codUnits?: IProfileCodeUnitAssignment[],
  tsStart?: number,
  tsEnd?: number,
  flgReboot?: boolean
}

export interface IProfileCodeUnitAssignment {
  codUnit: string
}

export interface IProfileActivation extends IProfileAssignment {
  tsActivation?: number
}

export interface IProfileActivationStatistic {
  idProfile: number,
  codStatistic: string,
  partial: number,
  total: number
}

export interface IProfileActivationDetail {
  idProfile: number,
  tsActivation: number,
  numerUnits: number,
  numberOk: number,
  numberWarning: number,
  numberKO: number,
  numberNotAppl: number
}

export interface IProfileDistribution {
  id?: number,
  idProfile: number,
  idAsset?: number,
  idType?: number,
  profileType?: string,
  description: string,
  flgReboot?: boolean,
  profile?: string,
  progress?: string,
  status?: string,
  action?: string,
  tsStart: number,
  tsCreated?: number,
  idCompany?: number
}

export interface IprofileAppConfig {
  profileType: string;
  packageName: string;
  formValue: UntypedFormGroup,
  applicationPolicyTableRows?: IApplicationPolicy[];
}

export enum ProfileStatus {
  "ACTIVATED" = "ACTIVATED",
  "ASSIGNED" = "ASSIGNED",
  "ERROR" = "ERROR"
}

export enum ProfileDistributionStatus {
  "DONE" = "DONE",
  "ERROR" = "ERROR",
  "WAITING" = "WAITING",
  "RUNNING" = "RUNNING",
  "NOT_SCHEDULATED" = "NOT_SCHEDULATED"
}

export enum ProfileDistributionAction {
  "UPGRADE" = "UPGRADE",
  "DOWNGRADE" = "DOWNGRADE"
}

